import { gql } from '@apollo/client';
export const getPerformanceMetricsFilterQuery = gql `
  query performanceMetricsFilter($organizationID: ID!) {
    performanceMetricsFilter(organizationID: $organizationID) {
      protocolNames
      users {
        id
        name
      }
      teams {
        id
        name
        members {
          user {
            id
            name
          }
        }
      }
    }
  }
`;
export const createPerformanceViewMutation = gql `
  mutation createPerformanceView($input: PerformanceViewInput!) {
    createPerformanceView(input: $input) {
      id
    }
  }
`;
export const updatePerformanceViewMutation = gql `
  mutation updatePerformanceView($input: PerformanceViewInput!) {
    updatePerformanceView(input: $input) {
      id
      title
      visibility
      trackers {
        text
        title
        key {
          entityID
          type
        }
        isEnabled
      }
      protocolNames
      users {
        id
        name
      }
      teams {
        id
        name
      }
      goal {
        enabled
        value
      }
      locked
      createdBy {
        id
        name
      }
    }
  }
`;
export const getPerformanceViewQuery = gql `
  query performanceView($id: String!) {
    performanceView(id: $id) {
      id
      title
      visibility
      trackers {
        text
        title
        key {
          entityID
          type
        }
        isEnabled
      }
      protocolNames
      users {
        id
        name
      }
      teams {
        id
        name
      }
      goal {
        enabled
        value
      }
      locked
      createdBy {
        id
        name
      }
    }
  }
`;
export const deletePerformanceViewMutation = gql `
  mutation deletePerformanceView($id: String!) {
    deletePerformanceView(id: $id)
  }
`;
