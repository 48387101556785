import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css } from '@corti/style';
import { Base, Modal, Sidebar as SidebarComponent } from 'lib/cortiUI';
import { useGlobalContext } from '../../../context';
import { TrackersConfigModalContent } from '../TrackersConfigModal';
import { PerformanceSidebarLoader } from './PerformanceSidebarLoader';
import { SidebarHeader } from './SidebarHeader';
import { ViewsSection } from './ViewsSection';
export const PerformanceSidebar = ({ isPerformanceViewsLoading, views, onTrackersApply, }) => {
    const { t } = useTranslation('performanceApp', { keyPrefix: 'sidebar' });
    const { isEditMode, isDraft } = useGlobalContext();
    const isSidebarDisabled = isEditMode || isDraft;
    const publicViews = views
        .filter((it) => it.visibility === 'PUBLIC')
        .sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }));
    const privateViews = views
        .filter((it) => it.visibility === 'PRIVATE')
        .sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }));
    const [isTrackersModalOpen, setIsTrackersModalOpen] = React.useState(false);
    const handleNewViewClick = () => {
        setIsTrackersModalOpen(true);
    };
    return (React.createElement(Base, { className: css({
            pointerEvents: isSidebarDisabled ? 'none' : 'inherit',
            opacity: isSidebarDisabled ? 0.5 : 1,
        }) },
        React.createElement(SidebarComponent, { defaultMode: "opened-perm", keepMounted: true }, isPerformanceViewsLoading ? (React.createElement(PerformanceSidebarLoader, null)) : (React.createElement(React.Fragment, null,
            React.createElement(SidebarHeader, { disabled: isSidebarDisabled, onNewViewClick: handleNewViewClick, isDraftView: isDraft }),
            privateViews.length > 0 && (React.createElement(ViewsSection, { views: privateViews, title: t('private', 'Private'), disabled: isSidebarDisabled })),
            publicViews.length > 0 && (React.createElement(ViewsSection, { views: publicViews, title: t('public', 'Public'), showFolders: true, disabled: isSidebarDisabled })),
            React.createElement(Modal, { open: isTrackersModalOpen, onClose: () => setIsTrackersModalOpen(false) },
                React.createElement(TrackersConfigModalContent, { onApply: (value) => {
                        onTrackersApply(value);
                        setIsTrackersModalOpen(false);
                    } })))))));
};
