import { useQuery } from '@apollo/client';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
import MetadataLoader from './MetadataLoader';
import { CopyableProperty, ExpandableProperties, SingleProperty } from './components';
import { getCaseMetadataQuery } from './graphql';
export const Metadata = ({ caseID, callID }) => {
    const { t } = useTranslation('caseView', { keyPrefix: 'metadata' });
    const { data: caseMetadataData, loading: isCaseMetadataLoading, error: caseMetadataError, } = useQuery(getCaseMetadataQuery, {
        variables: {
            id: caseID,
        },
    });
    if (caseMetadataError || !(caseMetadataData === null || caseMetadataData === void 0 ? void 0 : caseMetadataData.case)) {
        return null;
    }
    if (isCaseMetadataLoading) {
        return React.createElement(MetadataLoader, null);
    }
    const { case: caseMetadata } = caseMetadataData;
    const call = caseMetadata.calls.find((it) => it.id === callID);
    return (React.createElement(Base, { display: "grid", gridTemplateRows: "1fr auto" },
        React.createElement(Base, { display: "grid", gridTemplateRows: "auto auto 1fr", gap: 6 },
            React.createElement(Typography, { variant: "subtitle1", color: "default" }, t('callDetails', 'Call Details')),
            React.createElement(Base, { display: "grid", gap: 6 },
                (call === null || call === void 0 ? void 0 : call.start) && (React.createElement(SingleProperty, { title: t('date', 'Date'), value: formatDateTime(new Date(call.start)) })),
                (call === null || call === void 0 ? void 0 : call.user) && React.createElement(SingleProperty, { title: t('agent', 'Agent'), value: call.user.name }),
                (call === null || call === void 0 ? void 0 : call.protocolName) && (React.createElement(SingleProperty, { title: t('callType', 'Call Type'), value: call.protocolName.toString() }))),
            caseMetadata.customProperties &&
                !!Object.entries(caseMetadata.customProperties).length && (React.createElement(ExpandableProperties, { title: t('customProperties', 'Custom properties'), items: Object.entries(caseMetadata.customProperties).map(([key, value]) => ({
                    title: key,
                    value: value,
                })) }))),
        React.createElement(Base, { display: "flex" },
            React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4 },
                (call === null || call === void 0 ? void 0 : call.externalID) && (React.createElement(CopyableProperty, { title: t('externalId', 'External ID'), value: call.externalID })),
                React.createElement(CopyableProperty, { title: t('caseId', 'Case ID'), value: caseMetadata.id })))));
};
