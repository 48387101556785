var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { css } from '@corti/style';
import { useTheme } from '@corti/theme';
import { Base } from 'lib/cortiUI/components/Base';
/**
 * Card is a very simple low level component which is by default elevated, has rounded border and padding. It should be used as a base to compose more complex higher level components
 */
export const Card = React.forwardRef(function Card(props, ref) {
    const theme = useTheme();
    const { className, children, clickable, elevation = 4, borderRadius = theme.shape.borderRadius, active } = props, rest = __rest(props, ["className", "children", "clickable", "elevation", "borderRadius", "active"]);
    const [isHovered, setIsHovered] = React.useState(false);
    const [isFocused, setIsFocused] = React.useState(false);
    function onMouseEnter(e) {
        if (clickable) {
            setIsHovered(true);
        }
        props.onMouseEnter && props.onMouseEnter(e);
    }
    function onMouseLeave(e) {
        if (clickable) {
            setIsHovered(false);
        }
        props.onMouseLeave && props.onMouseLeave(e);
    }
    function onFocus(e) {
        if (clickable) {
            setIsFocused(true);
        }
        props.onFocus && props.onFocus(e);
    }
    function onBlur(e) {
        if (clickable) {
            setIsFocused(false);
        }
        props.onBlur && props.onBlur(e);
    }
    return (React.createElement(Base, Object.assign({ pl: 4, pr: 4, pt: 4, pb: 4, borderRadius: borderRadius, className: css({
            cursor: clickable ? 'pointer' : 'default',
            border: `1px solid ${theme.palette.background.divider}`,
        }, className), elevation: elevation || elevation === 0 ? elevation : active || isHovered || isFocused ? 8 : 1, tabIndex: clickable ? 0 : -1, background: isHovered ? theme.palette.action.hover : theme.palette.background.card }, rest, { ref: ref, onFocus: onFocus, onBlur: onBlur, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }), children));
});
export { CardActions, CardContent, CardHeader } from '@mui/material';
