import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { isEqual } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { subDays } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { coreStore } from 'browser/stores';
import { useAuth } from 'core/auth/browser';
import { useGlobalContext } from '../../context';
import { getPerformanceViewsQuery } from '../../graphql';
import { useParametersCache } from '../../utils';
import { createPerformanceViewMutation, deletePerformanceViewMutation, getPerformanceMetricsFilterQuery, getPerformanceViewQuery, updatePerformanceViewMutation, } from './graphql';
const parametersInitialState = {
    dateRange: {
        startDate: new Date(),
        endDate: new Date(),
    },
    appliedFilters: {
        trackers: [],
        protocolNames: [],
        teamIDs: [],
        userIDs: [],
    },
    filterOptions: {
        protocolNames: [],
        teams: [],
        users: [],
    },
    goal: {
        enabled: false,
        value: 60,
    },
    title: 'New View',
    visibility: 'PRIVATE',
    locked: false,
    isChanged: false,
    createdBy: undefined,
    isPerformanceViewLoading: false,
    isPerformanceMetricsFilterLoading: false,
    isCreateOrUpdateViewLoading: false,
    changeDateRange: () => undefined,
    changeFilters: () => undefined,
    changeTitle: () => undefined,
    changeGoal: () => undefined,
    changeVisibility: () => undefined,
    changeLocked: () => undefined,
    createPerformanceView: () => undefined,
    updatePerformanceView: () => undefined,
    deletePerformanceView: () => undefined,
    resetView: () => undefined,
    discardDraftView: () => undefined,
};
const DEFAULT_PERIOD = 7;
const ParametersContext = React.createContext(parametersInitialState);
export const ParametersProvider = ({ performanceViewID, draftTrackers, onDiscardDraftView, children }) => {
    var _a, _b;
    const { t } = useTranslation('performanceApp');
    const { organization, currentUserID } = useAuth();
    const history = useHistory();
    const { isEditMode, isDraft, toggleEditMode } = useGlobalContext();
    const cacheHandlers = useParametersCache(performanceViewID);
    const [dateRange, setDateRange] = React.useState(getInitialDateRange());
    const [title, setTitle] = React.useState(parametersInitialState.title);
    const [goal, setGoal] = React.useState(parametersInitialState.goal);
    const [visibility, setVisibility] = React.useState(parametersInitialState.visibility);
    const [locked, setLocked] = React.useState(false);
    const [appliedFilters, setAppliedFilters] = React.useState(parametersInitialState.appliedFilters);
    const { data: performanceMetricsFilterData, loading: isPerformanceMetricsFilterLoading } = useQuery(getPerformanceMetricsFilterQuery, {
        variables: {
            organizationID: organization.id,
        },
    });
    const [getViewQuery, getViewResult] = useLazyQuery(getPerformanceViewQuery, {
        onCompleted: ({ performanceView: { protocolNames, trackers, users, teams, title, goal, visibility, locked }, }) => {
            var _a, _b, _c;
            setAppliedFilters(cacheHandlers.getPersistedFilters() || {
                protocolNames: protocolNames !== null && protocolNames !== void 0 ? protocolNames : [],
                trackers: (_a = trackers === null || trackers === void 0 ? void 0 : trackers.map((it) => it.key)) !== null && _a !== void 0 ? _a : [],
                userIDs: (_b = users === null || users === void 0 ? void 0 : users.map((it) => it.id)) !== null && _b !== void 0 ? _b : [],
                teamIDs: (_c = teams === null || teams === void 0 ? void 0 : teams.map((it) => it.id)) !== null && _c !== void 0 ? _c : [],
            });
            changeTitle(title);
            setGoal(cacheHandlers.getPersistedGoal() || goal);
            changeVisibility(visibility);
            changeLocked(locked);
        },
        fetchPolicy: 'network-only',
    });
    /**
     * Temporary solution until the proper edit mode UI & functionality is implemented
     * TODO: Remove me later
     */
    React.useEffect(() => {
        if (isEditMode) {
            toggleEditMode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performanceViewID]);
    React.useEffect(() => {
        if (performanceViewID === 'draft') {
            changeFilters(draftTrackers.length !== 0
                ? Object.assign(Object.assign({}, parametersInitialState.appliedFilters), { trackers: draftTrackers }) : cacheHandlers.getPersistedFilters() || parametersInitialState.appliedFilters);
            changeGoal(cacheHandlers.getPersistedGoal() || parametersInitialState.goal);
            changeTitle(parametersInitialState.title);
            changeVisibility(parametersInitialState.visibility);
            changeLocked(parametersInitialState.locked);
        }
        else {
            getViewQuery({
                variables: {
                    id: performanceViewID,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draftTrackers, getViewQuery, performanceViewID]);
    const performanceViewData = (_a = getViewResult.data) === null || _a === void 0 ? void 0 : _a.performanceView;
    const shouldPerformIsChangedCheck = (performanceViewData && !getViewResult.loading) || isDraft;
    const isChanged = shouldPerformIsChangedCheck
        ? !isEqual({
            goal: {
                enabled: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.goal.enabled,
                value: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.goal.value,
            },
            trackers: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.trackers.map((it) => it.key).sort((a, b) => a.entityID.localeCompare(b.entityID)),
            protocolNames: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.protocolNames,
            teamIDs: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.teams.map((it) => it.id),
            userIDs: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.users.map((it) => it.id),
            title: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.title,
            visibility: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.visibility,
            locked: performanceViewData === null || performanceViewData === void 0 ? void 0 : performanceViewData.locked,
        }, Object.assign(Object.assign({ goal: {
                enabled: goal.enabled,
                value: goal.value,
            } }, appliedFilters), { trackers: appliedFilters.trackers.sort((a, b) => a.entityID.localeCompare(b.entityID)), title,
            visibility,
            locked }))
        : false;
    const [_createPerformanceView, { loading: isCreatePerformanceViewLoading }] = useMutation(createPerformanceViewMutation, {
        variables: {
            input: {
                organizationID: organization.id,
                createdByID: currentUserID,
                title,
                trackers: appliedFilters.trackers,
                protocolNames: appliedFilters.protocolNames,
                userIDs: appliedFilters.userIDs,
                teamIDs: appliedFilters.teamIDs,
                goal,
                visibility,
                locked,
            },
        },
        refetchQueries: [
            {
                query: getPerformanceViewsQuery,
                variables: {
                    organizationID: organization.id,
                    userID: currentUserID,
                },
            },
        ],
        onError: (error) => {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('createViewFailureMsg', 'Failed to create the view'),
                detailText: error.message,
                closable: true,
            });
        },
        onCompleted: ({ createPerformanceView: { id } }) => {
            cacheHandlers.clearCache();
            history.push(`/performance/views/${id}`);
            coreStore.notifications.showNotification({
                type: 'success',
                message: t('createViewSuccessMsg', 'The view has been created'),
            });
        },
    });
    const [_updatePerformanceView, { loading: isUpdatePerformanceViewLoading }] = useMutation(updatePerformanceViewMutation, {
        variables: {
            input: {
                id: performanceViewID,
                organizationID: organization.id,
                createdByID: (_b = getViewResult.data) === null || _b === void 0 ? void 0 : _b.performanceView.createdBy.id,
                title,
                trackers: appliedFilters.trackers,
                protocolNames: appliedFilters.protocolNames,
                userIDs: appliedFilters.userIDs,
                teamIDs: appliedFilters.teamIDs,
                goal,
                visibility,
                locked,
            },
        },
        onError: (error) => {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('updateViewFailureMsg', 'Failed to update the view'),
                detailText: error.message,
                closable: true,
            });
        },
        onCompleted() {
            coreStore.notifications.showNotification({
                type: 'success',
                message: t('updateViewSuccessMsg', 'Edits are saved'),
            });
        },
    });
    const [_deletePerformanceView] = useMutation(deletePerformanceViewMutation, {
        variables: {
            id: performanceViewID,
        },
        refetchQueries: [
            {
                query: getPerformanceViewsQuery,
                variables: {
                    organizationID: organization.id,
                    userID: currentUserID,
                },
            },
        ],
        awaitRefetchQueries: true,
        onError: (error) => {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('deleteViewFailureMsg', 'Failed to delete the view'),
                detailText: error.message,
                closable: true,
            });
        },
        onCompleted() {
            cacheHandlers.clearCache();
            history.push('/performance');
            coreStore.notifications.showNotification({
                type: 'success',
                message: t('deleteViewSuccessMsg', 'The view has been deleted'),
            });
        },
    });
    function createPerformanceView(title, visibility, locked) {
        void _createPerformanceView({
            variables: {
                input: {
                    organizationID: organization.id,
                    createdByID: currentUserID,
                    title,
                    trackers: appliedFilters.trackers,
                    protocolNames: appliedFilters.protocolNames,
                    userIDs: appliedFilters.userIDs,
                    teamIDs: appliedFilters.teamIDs,
                    goal,
                    visibility,
                    locked,
                },
            },
        });
    }
    function updatePerformanceView() {
        void _updatePerformanceView().then(({ data }) => {
            const { updatePerformanceView: { protocolNames, trackers, users, teams, title, goal, visibility, locked, }, } = data;
            changeFilters({
                protocolNames,
                trackers: trackers.map((it) => it.key),
                userIDs: users.map((it) => it.id),
                teamIDs: teams.map((it) => it.id),
            });
            changeTitle(title);
            changeGoal(goal);
            changeVisibility(visibility);
            changeLocked(locked);
            cacheHandlers.clearCache();
            if (isEditMode)
                toggleEditMode();
        });
    }
    function deletePerformanceView() {
        void _deletePerformanceView();
    }
    function resetView() {
        var _a;
        const performanceView = (_a = getViewResult.data) === null || _a === void 0 ? void 0 : _a.performanceView;
        if (performanceView) {
            changeFilters({
                trackers: performanceView.trackers.map((it) => it.key),
                protocolNames: performanceView.protocolNames,
                teamIDs: performanceView.teams.map((it) => it.id),
                userIDs: performanceView.users.map((it) => it.id),
            });
            changeGoal(performanceView.goal);
            changeTitle(performanceView.title);
            changeVisibility(performanceView.visibility);
            changeLocked(performanceView.locked);
        }
        cacheHandlers.clearCache();
    }
    function discardDraftView() {
        onDiscardDraftView();
        changeFilters({ teamIDs: [], userIDs: [], protocolNames: [] });
        changeGoal({ value: 60, enabled: false });
        cacheHandlers.clearCache();
        history.push('/performance');
    }
    function getInitialDateRange() {
        const persistedDateRange = cacheHandlers.getPersistedDateRange();
        if (persistedDateRange) {
            return persistedDateRange;
        }
        return {
            startDate: subDays(parametersInitialState.dateRange.endDate, DEFAULT_PERIOD),
            endDate: parametersInitialState.dateRange.endDate,
        };
    }
    function changeFilters(filters) {
        setAppliedFilters((current) => (Object.assign(Object.assign({}, current), filters)));
        cacheHandlers.persistFilters(Object.assign(Object.assign({}, appliedFilters), filters));
    }
    function changeDateRange(newDateRange) {
        setDateRange(newDateRange);
        cacheHandlers.persistDateRange(newDateRange);
    }
    function changeTitle(newTitle) {
        setTitle(newTitle);
    }
    function changeVisibility(newVisibility) {
        setVisibility(newVisibility);
    }
    function changeLocked(locked) {
        setLocked(locked);
    }
    function changeGoal(newGoal) {
        setGoal(newGoal);
        cacheHandlers.persistGoal(newGoal);
    }
    const value = React.useMemo(() => {
        var _a, _b;
        return ({
            filterOptions: (_a = performanceMetricsFilterData === null || performanceMetricsFilterData === void 0 ? void 0 : performanceMetricsFilterData.performanceMetricsFilter) !== null && _a !== void 0 ? _a : parametersInitialState.filterOptions,
            appliedFilters,
            title,
            goal,
            visibility,
            dateRange,
            locked,
            isChanged,
            createdBy: (_b = getViewResult.data) === null || _b === void 0 ? void 0 : _b.performanceView.createdBy,
            isPerformanceViewLoading: getViewResult.loading,
            isPerformanceMetricsFilterLoading,
            isCreateOrUpdateViewLoading: isCreatePerformanceViewLoading || isUpdatePerformanceViewLoading,
            changeDateRange,
            changeFilters,
            changeTitle,
            changeVisibility,
            changeLocked,
            changeGoal,
            createPerformanceView,
            updatePerformanceView,
            deletePerformanceView,
            resetView,
            discardDraftView,
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        appliedFilters,
        dateRange,
        getViewResult.data,
        getViewResult.loading,
        goal,
        locked,
        isChanged,
        isCreatePerformanceViewLoading,
        isDraft,
        isEditMode,
        isPerformanceMetricsFilterLoading,
        isUpdatePerformanceViewLoading,
        performanceMetricsFilterData === null || performanceMetricsFilterData === void 0 ? void 0 : performanceMetricsFilterData.performanceMetricsFilter,
        title,
        visibility,
    ]);
    return React.createElement(ParametersContext.Provider, { value: value }, children);
};
export function useParametersContext() {
    const context = React.useContext(ParametersContext);
    if (!context) {
        throw new Error('useParametersContext must be used within a ParametersProvider');
    }
    return context;
}
