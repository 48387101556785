var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { useAuth } from 'core/auth/browser';
import { useParametersContext } from '../ParametersContext';
import { getPerformanceViewMetricsGroupByCallQuery, getPerformanceViewMetricsQuery, } from './graphql';
import { calculatePerformanceScore } from './utils';
const THRESHOLD = 15;
const initialState = {
    performanceViewMetrics: {
        metricsBreakdown: [],
        userMetrics: [],
        timeBasedMetrics: [],
    },
    performanceViewMetricsGroupByCall: {
        cursor: undefined,
        performanceMetrics: [],
    },
    threshold: THRESHOLD,
    performanceScore: 0,
    fetchMorePerformanceViewMetricsGroupByCall: (() => Promise.resolve({
        data: { performanceViewMetricsGroupByCall: [] },
        loading: false,
    })),
    isPerformanceViewMetricsLoading: false,
    isPerformanceViewMetricsGroupByCallLoading: false,
    totalNumberOfUsersCalls: 0,
};
export const DataContext = React.createContext(initialState);
export const DataContextProvider = ({ children }) => {
    var _a;
    const { organization } = useAuth();
    const { dateRange, appliedFilters } = useParametersContext();
    const { trackers } = appliedFilters, filter = __rest(appliedFilters, ["trackers"]);
    const [getPerformanceViewMetrics, { data: performanceViewMetrics, loading: isPerformanceViewMetricsLoading },] = useLazyQuery(getPerformanceViewMetricsQuery, {
        variables: {
            organizationID: organization.id,
            startTime: dateRange.startDate,
            endTime: dateRange.endDate,
            filter: filter.userIDs.length ? Object.assign(Object.assign({}, filter), { teamIDs: [] }) : filter,
            sources: trackers,
        },
        fetchPolicy: 'network-only',
    });
    React.useEffect(() => {
        if (appliedFilters.trackers.length) {
            getPerformanceViewMetrics();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedFilters.trackers]);
    const [getPerformanceViewMetricsGroupByCall, { fetchMore: fetchMorePerformanceViewMetricsGroupByCall, data: performanceViewMetricsGroupByCall, loading: isPerformanceViewMetricsGroupByCallLoading, },] = useLazyQuery(getPerformanceViewMetricsGroupByCallQuery, {
        variables: {
            organizationID: organization.id,
            startTime: dateRange.startDate,
            endTime: dateRange.endDate,
            filter: filter.userIDs.length ? Object.assign(Object.assign({}, filter), { teamIDs: [] }) : filter,
            sources: trackers,
            limit: 25,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    React.useEffect(() => {
        if (filter.userIDs.length) {
            getPerformanceViewMetricsGroupByCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.userIDs]);
    const totalNumberOfUsersCalls = (_a = performanceViewMetrics === null || performanceViewMetrics === void 0 ? void 0 : performanceViewMetrics.performanceViewMetrics.userMetrics.reduce((sum, item) => sum + item.numberOfCalls, 0)) !== null && _a !== void 0 ? _a : initialState.totalNumberOfUsersCalls;
    const value = React.useMemo(() => {
        var _a, _b, _c;
        return ({
            performanceViewMetrics: (_a = performanceViewMetrics === null || performanceViewMetrics === void 0 ? void 0 : performanceViewMetrics.performanceViewMetrics) !== null && _a !== void 0 ? _a : initialState.performanceViewMetrics,
            performanceViewMetricsGroupByCall: (_b = performanceViewMetricsGroupByCall === null || performanceViewMetricsGroupByCall === void 0 ? void 0 : performanceViewMetricsGroupByCall.performanceViewMetricsGroupByCall) !== null && _b !== void 0 ? _b : initialState.performanceViewMetricsGroupByCall,
            fetchMorePerformanceViewMetricsGroupByCall,
            isPerformanceViewMetricsLoading,
            isPerformanceViewMetricsGroupByCallLoading,
            totalNumberOfUsersCalls,
            performanceScore: calculatePerformanceScore((_c = performanceViewMetrics === null || performanceViewMetrics === void 0 ? void 0 : performanceViewMetrics.performanceViewMetrics.metricsBreakdown) !== null && _c !== void 0 ? _c : []),
            threshold: initialState.threshold,
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
        performanceViewMetrics,
        isPerformanceViewMetricsLoading,
        performanceViewMetricsGroupByCall,
        isPerformanceViewMetricsGroupByCallLoading,
    ]);
    return React.createElement(DataContext.Provider, { value: value }, children);
};
export function useDataContext() {
    const context = React.useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataContextProvider');
    }
    return context;
}
