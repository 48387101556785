var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Autocomplete, MenuItem, TextField, Typography } from 'lib/cortiUI';
export const MultipleSelect = (_a) => {
    var { inputLabel } = _a, autocompleteProps = __rest(_a, ["inputLabel"]);
    return (React.createElement(Autocomplete, Object.assign({}, autocompleteProps, { multiple: true, limitTags: 0, getLimitTagsText: (more) => `${more} selected`, size: "small", disableCloseOnSelect: true, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: inputLabel, size: "small" })), renderOption: (props, option, state) => {
            var _a, _b;
            return (React.createElement(MenuItem, Object.assign({}, props, { selected: state.selected }),
                React.createElement(Typography, { variant: "body2", noWrap: true }, (_b = (_a = autocompleteProps.getOptionLabel) === null || _a === void 0 ? void 0 : _a.call(autocompleteProps, option)) !== null && _b !== void 0 ? _b : '')));
        } })));
};
