import React from 'react';
import { useTranslation } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useTheme } from '@corti/theme';
import { authStore } from 'core/auth';
import { config } from 'core/configuration/browser';
import { Base, FallbackView, Typography } from 'lib/cortiUI';
import { useCallControls } from '../CallControlsContext';
import { Phrase } from './Phrase';
import { TranscriptsLoader } from './TranscriptsLoader';
import { api, convertData, initTranscriptsApiService } from './service';
export const Transcripts = ({ callID }) => {
    const theme = useTheme();
    const { t } = useTranslation('caseView', { keyPrefix: 'call.transcripts' });
    const { currentCallTime, changeCurrentCallTime } = useCallControls();
    const [transcripts, setTranscripts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        initTranscriptsApiService({
            host: config.getConfig().apiHost,
            authToken: authStore.authToken,
        });
    }, []);
    React.useEffect(() => {
        void fetchTranscripts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callID]);
    async function fetchTranscripts() {
        setIsLoading(true);
        try {
            if (api) {
                const res = await api.getCallTranscript(callID);
                setTranscripts(convertData(res));
            }
        }
        catch (e) {
            console.error(`Could not load Transcripts for Call`, e);
        }
        finally {
            setIsLoading(false);
        }
    }
    if (isLoading) {
        return React.createElement(TranscriptsLoader, null);
    }
    if (!transcripts.length) {
        return React.createElement(FallbackView, { title: t('noTranscripts', 'No transcript for this call') });
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4, height: "100%", overflow: "hidden" },
        React.createElement(Typography, { color: "default" }, t('transcripts', 'Transcripts')),
        React.createElement(Base, { display: "flex", flexDirection: "column", gap: 4, className: css({ overflow: 'overlay' }, getScrollerCss({ theme: theme })), pb: 4 }, transcripts.map((it) => (React.createElement(Phrase, Object.assign({}, it, { key: it.time.toString(), isActive: it.time <= currentCallTime, onClick: () => changeCurrentCallTime(it.time, true) })))))));
};
