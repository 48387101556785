import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import React from 'react';
import { css } from '@corti/style';
import { Base, Typography, useCortiUIContext } from 'lib/cortiUI';
import { getValueProgressBarColor } from '../utils';
const DEFAULT_SIZE = 120;
highchartsMore(Highcharts);
solidGauge(Highcharts);
export function DetectionsCircularProgressBar(props) {
    const { value = 0, size = DEFAULT_SIZE, label, target, disableAnimation = false } = props;
    const { theme } = useCortiUIContext();
    function getOptions() {
        const isDark = theme.palette.mode === 'dark';
        const paneColor = isDark ? theme.palette.grey[700] : theme.palette.grey[300];
        return {
            chart: {
                animation: !disableAnimation,
                height: size,
                width: size,
                type: 'solidgauge',
                backgroundColor: 'transparent',
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0,
                spacingTop: 0,
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
            },
            tooltip: {
                enabled: false,
            },
            pane: {
                size: '100%',
                startAngle: 0,
                endAngle: 360,
                background: [
                    {
                        outerRadius: '100%',
                        innerRadius: getInnerRadius(),
                        backgroundColor: paneColor,
                        borderWidth: 0,
                    },
                ],
            },
            yAxis: {
                min: 0,
                max: 1,
                lineWidth: 0,
                tickPositions: [],
            },
            plotOptions: {
                series: {
                    animation: {
                        defer: 0,
                        duration: disableAnimation ? 0 : 1000,
                    },
                },
                solidgauge: {
                    dataLabels: {
                        enabled: false,
                    },
                },
            },
            series: [
                {
                    zIndex: 1,
                    data: [
                        {
                            color: getValueProgressBarColor({ value: value, target: target, theme }),
                            innerRadius: getInnerRadius(),
                            y: value,
                        },
                    ],
                },
            ],
        };
    }
    function getInnerRadius() {
        if (size < 50) {
            return '70%';
        }
        else if (size < 100) {
            return '75%';
        }
        else if (size < 150) {
            return '80%';
        }
        else {
            return '85%';
        }
    }
    return (React.createElement(Base, { display: "flex", flexDirection: "row", alignItems: "center", gap: 4 },
        label && (React.createElement(Typography, { variant: "body2", color: "default", className: css({
                fontVariantNumeric: 'tabular-nums',
            }) }, label)),
        React.createElement(HighchartsReact, { highcharts: Highcharts, options: getOptions() })));
}
