import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
export function AssessmentPending() {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment' });
    return (React.createElement(Base, { m: "auto" },
        React.createElement(Base, { display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" },
            React.createElement(TimelapseRoundedIcon, null),
            React.createElement(Typography, { variant: "body2" }, t('assessmentPendingText', 'Agent Assessment for this call will be available shortly')))));
}
