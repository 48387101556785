import { sortBy } from 'lodash';
import React from 'react';
import { intl } from '@corti/i18n';
import { css, getScrollerCss } from '@corti/style';
import { useParametersContext } from 'browser/pages/performance/activeView/context/ParametersContext';
import { CallMetricsBreakdownItem, DetectionsCircularProgressBar } from 'lib/cortiAnalyticsUI';
import { Base, Card, CardContent, Tooltip, Typography, useCortiUIContext } from 'lib/cortiUI';
export const CallMetricsWidget = ({ callMetrics }) => {
    const { goal } = useParametersContext();
    const { theme } = useCortiUIContext();
    const detectionsWithDescriptor = sortBy(callMetrics, (it) => it.source.text);
    const detectedQuestions = detectionsWithDescriptor.filter((it) => it.value !== 'not_detected').length;
    return (React.createElement(Tooltip, { className: css({
            backgroundColor: 'transparent',
            padding: 0,
            maxWidth: 'unset',
        }), TransitionProps: {
            timeout: 0,
        }, title: React.createElement(Card, { maxHeight: "50vh", overflow: "hidden", display: "flex", flexDirection: "column" },
            React.createElement(Typography, { p: 4, variant: "h6", width: "100%", color: "default" }, intl.t('performanceApp:questionBreakdown', 'Breakdown')),
            React.createElement(CardContent, { sx: { pt: 0, display: 'flex', flexDirection: 'column', gap: 3 }, className: css(getScrollerCss({ theme })) }, detectionsWithDescriptor.map((it) => (React.createElement(CallMetricsBreakdownItem, { title: it.source.text, value: it.value, key: it.source.key.entityID + it.source.key.type }))))) },
        React.createElement(Base, null,
            React.createElement(DetectionsCircularProgressBar, { target: goal.enabled ? goal.value / 100 : undefined, value: detectedQuestions / callMetrics.length, label: `${detectedQuestions} /  ${callMetrics.length}`, size: 23 }))));
};
