import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import React from 'react';
import { differenceInMilliseconds, formatDateTime } from '@corti/date';
import { intl } from '@corti/i18n';
import { Formatters } from '@corti/strings';
import { Base, Tooltip, Typography } from 'lib/cortiUI';
import { AcknowledgementChip, PerformanceChip, ScoreLabel } from '../../../components';
import { PendingAiScoreIndicator } from '../components';
const getCallDuration = (start, end) => {
    return Formatters.msToDuration(differenceInMilliseconds(new Date(end), new Date(start)));
};
export const getDataGridRowsData = (items) => items.map((item) => {
    var _a;
    return ({
        id: item.id,
        dateTime: item.call_reference.started_at,
        caseID: item.case_id,
        user: ((_a = item.user) === null || _a === void 0 ? void 0 : _a.name) || '-',
        callDuration: getCallDuration(item.call_reference.started_at, item.call_reference.ended_at),
        interactionStatus: item.acknowledgement_status,
        score: {
            processingStatus: item.status,
            score: Math.floor(item.score_percentage),
        },
    });
});
export const getDataGridColumnsData = (thresholds) => [
    {
        field: 'dateTime',
        headerName: intl.t('missionControlApp:callsDataGrid.dateTime', 'Time & Date'),
        editable: false,
        type: 'dateTime',
        flex: 3,
        renderCell: ({ value }) => (React.createElement(Typography, { color: "default", variant: "body2", noWrap: true }, value ? formatDateTime(new Date(value)) : '-')),
    },
    {
        field: 'user',
        headerName: intl.t('missionControlApp:callsDataGrid.user', 'Agent'),
        editable: false,
        flex: 2,
    },
    {
        field: 'callDuration',
        headerName: intl.t('missionControlApp:callsDataGrid.duration', 'Duration'),
        editable: false,
        flex: 2,
    },
    {
        field: 'interactionStatus',
        headerName: intl.t('missionControlApp:callsDataGrid.feedbackStatus', 'Acknowledgment Status'),
        editable: false,
        flex: 2,
        renderCell: ({ value }) => {
            if (!value)
                return '-';
            return React.createElement(AcknowledgementChip, { status: value });
        },
    },
    {
        field: 'score',
        headerName: intl.t('missionControlApp:callsDataGrid.score', 'Score'),
        editable: false,
        type: 'number',
        flex: 2,
        valueGetter: (params) => {
            return params.value.score || null;
        },
        renderCell: ({ row }) => {
            // get the original (unformatted) value from the row (the valueGetter is not applied here)
            const value = row === null || row === void 0 ? void 0 : row.score;
            if (!value || value.processingStatus === 'PENDING_TRANSCRIPT')
                return React.createElement(PendingAiScoreIndicator, null);
            if (value.processingStatus === 'FAILED') {
                return (React.createElement(Tooltip, { title: intl.t('missionControlApp:callsDataGrid.reviewStatus.failed', 'Could not load data') },
                    React.createElement(ErrorRoundedIcon, { fontSize: "small", color: "action" })));
            }
            if (value.processingStatus === 'NONE' || !value.score)
                return '-';
            return (React.createElement(Base, { display: "flex", gap: 3, alignItems: "center" },
                React.createElement(ScoreLabel, { value: value.score, thresholds: thresholds }),
                React.createElement(PerformanceChip, { value: value.score, thresholds: thresholds })));
        },
    },
];
