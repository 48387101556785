import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import React from 'react';
import { formatDateTime } from '@corti/date';
import { useTheme } from '@corti/theme';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from 'lib/cortiUI';
import { GradientStarIcon } from '../components';
import { getReviewScoreByThresholdsColor } from '../utils';
const TEMPLATE_THRESHOLDS = {
    min: 20,
    max: 50,
};
export const ReviewItem = ({ selected = false, showScore = false, onClick, created_by, status_updated_at, score_percentage, }) => {
    var _a;
    const theme = useTheme();
    const scorePercentage = Math.floor(score_percentage);
    return (React.createElement(ListItemButton, { selected: selected, sx: { pointerEvents: selected ? 'none' : 'auto' }, onClick: onClick, border: `1px solid ${theme.palette.background.divider}`, dense: true },
        React.createElement(ListItemIcon, null, created_by ? React.createElement(PersonRoundedIcon, null) : React.createElement(GradientStarIcon, null)),
        React.createElement(ListItemText, { primary: React.createElement(Typography, { color: "default", variant: "body2" }, (_a = created_by === null || created_by === void 0 ? void 0 : created_by.name) !== null && _a !== void 0 ? _a : 'Corti AI'), secondary: formatDateTime(new Date(status_updated_at)) }),
        showScore && (React.createElement(ListItemIcon, null,
            React.createElement(BarChartRoundedIcon, { sx: {
                    color: getReviewScoreByThresholdsColor(scorePercentage, TEMPLATE_THRESHOLDS, theme.palette),
                } }),
            React.createElement(Typography, null, `${scorePercentage}%`)))));
};
