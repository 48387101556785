import { useQuery } from '@apollo/client';
import React from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base } from 'lib/cortiUI';
import { Route } from 'lib/router';
import { ActiveView } from './activeView/ActiveView';
import { GlobalContextProvider } from './context';
import { getPerformanceViewsQuery, } from './graphql';
import { InitialView } from './initialView';
import { PerformanceSidebar, ViewLoader } from './ui';
import { useParametersCache } from './utils';
export const PerformanceApp = () => {
    var _a, _b, _c, _d, _e, _f;
    const { organization, currentUserID } = useAuth();
    const history = useHistory();
    const draftViewCacheHandlers = useParametersCache('draft');
    const [initialMetricSourceKeys, setInitialMetricSourceKeys] = React.useState([]);
    const { data: getPerformanceViews, loading } = useQuery(getPerformanceViewsQuery, {
        variables: {
            organizationID: organization.id,
            userID: currentUserID,
        },
    });
    const sortedViews = (_b = (_a = getPerformanceViews === null || getPerformanceViews === void 0 ? void 0 : getPerformanceViews.performanceViews) === null || _a === void 0 ? void 0 : _a.slice().sort((a, b) => {
        if (a.visibility === b.visibility) {
            return a.title.localeCompare(b.title);
        }
        else {
            return a.visibility === 'PRIVATE' ? -1 : 1;
        }
    })) !== null && _b !== void 0 ? _b : [];
    function handleApplyInitialMetricSourceKeys(metricSourceKeys) {
        setInitialMetricSourceKeys(metricSourceKeys);
        history.push(`/performance/views/draft`);
    }
    function redirectView() {
        if (sortedViews.length > 0) {
            const route = `/performance/views/${sortedViews[0].id}`;
            return React.createElement(Redirect, { to: route });
        }
    }
    if (loading && !((_d = (_c = getPerformanceViews === null || getPerformanceViews === void 0 ? void 0 : getPerformanceViews.performanceViews) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0)) {
        return React.createElement(ViewLoader, null);
    }
    if (!((_f = (_e = getPerformanceViews === null || getPerformanceViews === void 0 ? void 0 : getPerformanceViews.performanceViews) === null || _e === void 0 ? void 0 : _e.length) !== null && _f !== void 0 ? _f : 0) &&
        initialMetricSourceKeys.length === 0 &&
        //We add an additional check to see if anything is in the cache. This is to fix the issue of falling back to the Initial View when there are no views created.
        !draftViewCacheHandlers.getPersistedFilters()) {
        return React.createElement(InitialView, { onTrackersApply: handleApplyInitialMetricSourceKeys });
    }
    return (React.createElement(Base, { "data-cy": "performance-app", width: "100%", height: "100%", display: "grid", gridTemplateColumns: "auto 1fr", overflow: "hidden" },
        React.createElement(GlobalContextProvider, null,
            React.createElement(PerformanceSidebar, { views: sortedViews, isPerformanceViewsLoading: loading, onTrackersApply: handleApplyInitialMetricSourceKeys }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: '/performance', exact: true, render: redirectView }),
                !rbacService.performanceViewsCRUD() && (React.createElement(Route, { path: '/performance/views/draft', exact: true, render: () => React.createElement(Redirect, { to: "/performance" }) })),
                React.createElement(Route, { key: 'performanceView', path: '/performance/views/:viewID', breadcrumb: undefined, render: ({ match }) => (React.createElement(ActiveView, { performanceViewID: match.params.viewID, draftTrackers: initialMetricSourceKeys, onDiscardDraftView: () => setInitialMetricSourceKeys([]) })) })))));
};
