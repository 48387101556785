import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base } from 'lib/cortiUI';
import { MultipleSelect } from '../../components';
import { useParametersContext } from '../../context/ParametersContext';
export const Parameters = () => {
    const { t } = useTranslation();
    const { appliedFilters, filterOptions, isCreateOrUpdateViewLoading, changeFilters } = useParametersContext();
    const userOptions = appliedFilters.teamIDs.length > 0
        ? Array.from(new Set(filterOptions.teams
            .filter((team) => appliedFilters.teamIDs.includes(team.id))
            .flatMap((team) => team.members.map((member) => member.user))))
        : filterOptions.users;
    const sortedProtocolNames = [...filterOptions.protocolNames].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
    return (React.createElement(Base, { display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 3, alignItems: "center" },
        React.createElement(MultipleSelect, { options: sortedProtocolNames, value: appliedFilters.protocolNames, disabled: isCreateOrUpdateViewLoading, onChange: (_, value) => changeFilters({ protocolNames: value }), getOptionLabel: (option) => option, inputLabel: t('performanceApp:chiefComplaint', 'Chief Complaint') }),
        React.createElement(MultipleSelect, { options: filterOptions.teams, value: filterOptions.teams.filter((it) => appliedFilters.teamIDs.includes(it.id)), disabled: isCreateOrUpdateViewLoading, onChange: (_, value) => changeFilters({ teamIDs: value.map((v) => v.id), userIDs: [] }), getOptionLabel: (option) => option.name, inputLabel: t('performanceApp:team', 'Team') }),
        React.createElement(MultipleSelect, { options: userOptions, value: userOptions.filter((it) => appliedFilters.userIDs.includes(it.id)), disabled: isCreateOrUpdateViewLoading, onChange: (_, value) => changeFilters({ userIDs: value.map((v) => v.id) }), getOptionLabel: (option) => option.name, inputLabel: t('performanceApp:teamMembers', 'Team Members') })));
};
