import React from 'react';
import { rbacService } from 'browser/services/init';
import { useAuth } from 'core/auth/browser';
import { Base } from 'lib/cortiUI';
import { SectionWrapper } from '../components';
import { AcknowledgementStatus } from './AcknowledgementStatus';
import { Assessment } from './Assessment';
import { ManualAssessment } from './ManualAssessment';
import { InteractionProvider, NavigationProvider, useInteraction, useNavigation } from './context';
export const RightSidePanelContent = ({ caseOwner, call }) => {
    const { currentUserID } = useAuth();
    const { interaction } = useInteraction();
    const { view } = useNavigation();
    return (React.createElement(Base, { display: "grid", gridTemplateRows: "auto 1fr", flexDirection: "column", overflow: "hidden" },
        ((interaction === null || interaction === void 0 ? void 0 : interaction.interaction_owner) === currentUserID ||
            rbacService.missionControlOtherAssessmentsRead()) &&
            view === 'assessment' && (React.createElement(SectionWrapper, null,
            React.createElement(AcknowledgementStatus, null))),
        view === 'assessment' && (React.createElement(Assessment, { callID: call.callServiceCallId, caseOwner: caseOwner })),
        view === 'manual-assessment' && (React.createElement(ManualAssessment, { callID: call.callServiceCallId, callStart: call.start, callEnd: call.stop, callUserIDs: [call.user.id] }))));
};
export const RightSidePanel = (props) => {
    return (React.createElement(InteractionProvider, { callID: props.call.callServiceCallId },
        React.createElement(NavigationProvider, null,
            React.createElement(RightSidePanelContent, Object.assign({}, props)))));
};
