import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { Base, Typography } from 'lib/cortiUI';
export function NoAssessment() {
    const { t } = useTranslation('caseView', { keyPrefix: 'assessment' });
    return (React.createElement(Base, { m: "auto" },
        React.createElement(Base, { display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" },
            React.createElement(ErrorRoundedIcon, null),
            React.createElement(Typography, { variant: "body2" }, t('failedDescription', 'Could not load data')))));
}
